import {Injectable} from '@angular/core';
import {getDownloadURL, listAll, ref, Storage, uploadBytesResumable} from '@angular/fire/storage'
import {AuthService} from "@services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public uploading: boolean = false;
  public progress: number = 0; // %

  constructor(public storage: Storage, private authService: AuthService) { }

  public async addFile(file: any, name: string, folders: string): Promise<string | null> {
    /** store a file at folders ex: file, myImage, icons/sources*/
    await this.authService.refreshToken();
    return new Promise((resolve, reject) => {
      const storageRef = ref(this.storage, folders+'/'+name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on('state_changed',
        (snapshot) => {
          this.uploading = true;
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + this.progress + '% done');
        },
        (error) => {
          // Handle unsuccessful uploads
          console.warn('storage Error: ' + error.message);
          reject(error);
        },
        async () => {
          this.uploading = false;
          this.progress = 0;
          // Handle successful uploads on complete
          try {
            const downloadURL: string = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            resolve(null);
          }

        }
      );
    })
  }

  async listAllDefaultBackgrounds(): Promise<{name: string, url: string}[]> {
    const listRef = ref(this.storage, 'backgrounds/default/thumbnails');
    const res = await listAll(listRef);
    return await Promise.all(res.items.map(async (itemRef) => {
      const url = await getDownloadURL(itemRef);
      return {name: itemRef.name, url};
    }));
  }

  async listAllDefaultIcons48(): Promise<{name: string, url: string}[]> {
    const listRef = ref(this.storage, 'icons/default');
    const res = await listAll(listRef);

    const iconPromises = res.prefixes.map(async (folderRef) => {
      const iconRef = ref(this.storage, `${folderRef.fullPath}/icon48.png`);
      try {
        const url = await getDownloadURL(iconRef);
        const name = folderRef.name; // The wildcard part of the path
        return { name, url };
      } catch (error) {
        // Handle case where icon48 doesn't exist in the folder
        console.error(`Error getting icon48 for ${folderRef.fullPath}`, error);
        return null;
      }
    });

    const icons = await Promise.all(iconPromises);
    // Filter out any null results
    return icons.filter((icon): icon is { name: string, url: string } => icon !== null);
  }

  async listAllImagesInBucket(bucket: string | null): Promise<{name: string, url: string}[]> {
    console.log(bucket);
    if(bucket == null){
      return [];
    }
    const listRef = ref(this.storage, bucket);
    const res = await listAll(listRef);

    const imagePromises = res.items.map(async (itemRef) => {
      try {
        const url = await getDownloadURL(itemRef);
        const name = itemRef.name; // The name of the file
        return { name, url };
      } catch (error) {
        // Handle case where URL cannot be retrieved
        console.error(`Error getting URL for ${itemRef.fullPath}`, error);
        return null;
      }
    });

    const images = await Promise.all(imagePromises);
    // Filter out any null results
    return images.filter((image): image is { name: string, url: string } => image !== null);
  }

  public async getFile(target: string): Promise<string> {
    /** return file download url from {bucket}/{fileName} with file extension **/
    const storageRef = ref(this.storage, target);
    try {
      const url: string = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      console.error('Error getting file URL:', error);
      throw error;
    }
  }
}
