import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageUploadDialogComponent } from './image-upload-dialog/image-upload-dialog.component';
import { UploadFileDialogComponent } from "./upload-file-dialog/upload-file-dialog.component";
import { DialogWaitComponent } from "./dialog-wait/dialog-wait.component";
import {HoldableDirective} from "../directives/holdable.directive";

@NgModule({
  declarations: [
    ImageUploadDialogComponent,
    UploadFileDialogComponent,
    DialogWaitComponent,
    HoldableDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DragDropModule,
  ],
  exports: [
    HoldableDirective,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DragDropModule,
    ImageUploadDialogComponent,
    UploadFileDialogComponent,
    DialogWaitComponent
  ]
})
export class SharedModule { }
