import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-upload-dialog',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls: ['./image-upload-dialog.component.scss']
})
export class ImageUploadDialogComponent {
  selectedFile: File | null = null;
  errorMessage: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<ImageUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: string }
  ) {}

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.validateFile(file);
    }
  }

  validateFile(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.onload = () => {
        const isValid = this.validateImage(img, file.type);
        if (isValid) {
          this.selectedFile = file;
          this.errorMessage = null;
        }
      };
      img.onerror = () => {
        this.errorMessage = 'Impossible de lire le fichier. Veuillez choisir un autre fichier.';
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  validateImage(img: HTMLImageElement, fileType: string): boolean {
    const width = img.width;
    const height = img.height;

    if (this.data.type === 'icon') {
      if (!['image/png', 'image/jpeg', 'image/webp'].includes(fileType)) {
        this.errorMessage = 'Format de fichier non valide. Les formats acceptés sont .png, .jpeg et .webp.';
        return false;
      }
      if (width < 512 || height < 512 || width !== height) {
        this.errorMessage = 'L\'image doit être carrée avec une taille minimale de 512x512.';
        return false;
      }
    } else if (this.data.type === 'background') {
      if (!['image/jpeg', 'image/png', 'image/webp', 'image/heif'].includes(fileType)) {
        this.errorMessage = 'Format de fichier non valide. Les formats acceptés sont .jpeg, .png, .webp et .heif.';
        return false;
      }
      if ((width >= height && width < 2560 || height < 1440) || (width <= height && width < 1440 || height < 2560)) {
        this.errorMessage = 'L\'image doit avoir une taille minimale de 2560x1440 ou 1440x2560.';
        return false;
      }
    }
    return true;
  }

  onUpload() {
    if (this.selectedFile) {
      this.dialogRef.close(this.selectedFile);
    } else {
      this.errorMessage = 'Veuillez sélectionner un fichier.';
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
