<div class="column-container">
<h2 mat-dialog-title>Choisissez une image</h2>
<mat-dialog-content class="column-container gap10px">
  <input type="file" (change)="onFileSelected($event)" accept="image/*">
  <div *ngIf="errorMessage" style="color: red">{{ errorMessage }}</div>
</mat-dialog-content>
<mat-dialog-actions dir="rtl" class="row-container gap10px">
  <button mat-flat-button (click)="onUpload()" color="primary" [disabled]="!selectedFile || errorMessage">VALIDER</button>
  <button mat-button (click)="onCancel()">ANNULER</button>
</mat-dialog-actions>
</div>
