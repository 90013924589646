import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StorageService} from "@services/storage.service";

export interface UploadFileData {
  fileName: string;
  file: File;
  bucket: string;
  displayName?: string;
}

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrl: './upload-file-dialog.component.scss'
})
export class UploadFileDialogComponent {
  /** this component take an array of UploadFileData [{fileName: string, file: file, bucket: string, displayName?: string}]*/

  uploads: { file: UploadFileData, progress: number, url?: string }[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UploadFileData[],
    private storageService: StorageService,
    private dialogRef: MatDialogRef<UploadFileDialogComponent>
  ) {}

  ngOnInit() {
    this.data.forEach(fileData => {
      this.uploads.push({ file: fileData, progress: 0 });
      this.uploadFile(fileData);
    });
  }

  async uploadFile(fileData: UploadFileData) {
    console.log(fileData)
    try {
      await this.storageService.addFile(fileData.file, fileData.fileName, fileData.bucket);

      const upload = this.uploads.find(u => u.file === fileData);
      if (upload) {
        upload.progress = 100;
      }

      if (this.uploads.every(u => u.progress === 100)) {
        this.dialogRef.close(this.uploads.map(u => ({ ...u.file, progress: u.progress })));
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
}
