import {
  Directive,
  HostListener,
  EventEmitter,
  Output,
  Renderer2,
  Injector,
  ViewContainerRef,
  ElementRef
} from '@angular/core';
import {Observable, Subject, interval} from "rxjs";
import {takeUntil, tap, filter} from "rxjs/operators";
import {MatSpinner} from "@angular/material/progress-spinner";

@Directive({
  selector: '[holdable]'
})
export class HoldableDirective {
  @Output() holdTime: EventEmitter<number> = new EventEmitter<number>()
  private state: Subject<string> = new Subject()
  private cancel: Observable<string>

  constructor(private el: ElementRef,
              private renderer: Renderer2) {
    this.cancel = this.state.pipe(
      filter(v => v === 'cancel'),
      tap(v => {
        //console.log('hold stop');
        this.holdTime.emit(0);
      })
    )

    // Add the unselectable class to the button
    this.renderer.addClass(this.el.nativeElement, 'unselectable');
  }

  @HostListener('touchend', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('mouseleave', ['$event'])
  onExit(){
    this.state.next('cancel');
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onHold(){
    //console.log('Started Hold');
    this.state.next('start');
    const n: number = 100; //ms

    interval(n).pipe(
      takeUntil(this.cancel),
      tap(v => {
        this.holdTime.emit(v * n)
      }),
    ).subscribe();
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: MouseEvent) {
    event.preventDefault();
  }
}


