import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-wait',
  templateUrl: './dialog-wait.component.html',
  styleUrl: './dialog-wait.component.scss'
})
export class DialogWaitComponent {
  /** display a full screen wait dialog box to let server to things **/
  public tipsIndex: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, tips: string[] }) {}

  ngOnInit(): void {
    this.rotateTips();
  }

  rotateTips(): void {
    setInterval(() => {
      this.tipsIndex = (this.tipsIndex + 1) % this.data.tips.length;
    }, 2000);
  }
}
